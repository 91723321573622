import { Box, BoxProps } from "@mui/material";
import { useEffect, useState } from "react";

interface PreloadImageProps extends BoxProps {
  src: string;
  alt: string;
  onLoad?: () => void;
}

export default function PreloadImage({
  src,
  alt,
  onLoad,
  ...props
}: PreloadImageProps) {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.src = src;
    img.onload = () => {
      setLoaded(true);
      if (onLoad) {
        onLoad();
      }
    };
  }, [src, onLoad]);

  return (
    <Box
      component="img"
      src={src}
      alt={alt}
      sx={{ display: loaded ? "flex" : "none" }}
      {...props}
    />
  );
}

import { trackEvent, trackUserInteraction } from "@/services/analytics-adapter";
import { useHealthCheck } from "@/services/core-api-adapter";
import { Box, Fade, Stack, Typography } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import ButtonWithAnalytics from "../../../Common/components/ButtonWithAnalytics";
import { GetHelpFABConfig } from "@/theme";
import { convertPxToRem } from "@/utils";
import FullscreenBackground from "@/features/Common/components/FullscreenBackground";
import GetHelpCTAButton from "@/features/Common/components/GetHelpCTAButton";
import IconLoader from "@/features/Common/components/IconLoader";
import PreloadImage from "@/features/Common/components/PreloadImage";
import womanSmilingImage from "./woman-smiling.png";
import backgroundWatermarkImage from "./background.png";

interface WelcomeLandingScreenProps {
  onContinue: () => void;
  onSignUp: () => void;
}

export default function WelcomeLandingScreen({
  onContinue,
  onSignUp,
}: WelcomeLandingScreenProps) {
  const { t } = useTranslation();
  const { data, isFetching } = useHealthCheck();

  function onGetHelpClick() {
    trackUserInteraction({
      linkText: "WelcomeFlow | Get help",
      linkIntent: "navigational",
      linkScope: "button",
    });
  }

  function onSignInButtonClick() {
    trackEvent({
      event: "action.signInButtonClicked",
      source: "welcomeFlow.landing",
    });
    onContinue();
  }

  function onSignUpButtonClick() {
    trackEvent({
      event: "action.signUpButtonClicked",
      source: "welcomeFlow.landing",
    });
    onSignUp();
  }

  let buttonsOrAPIError = (
    <>
      <Typography variant="body1" align="center">
        {t("common.APINotAvailable")}
      </Typography>
    </>
  );

  if (isFetching) {
    buttonsOrAPIError = <></>;
  }

  if (!isFetching && data) {
    buttonsOrAPIError = (
      <Stack direction="row" spacing={2}>
        <ButtonWithAnalytics
          color="primary"
          fullWidth
          page="WelcomeFlow"
          text="Sign up"
          intent="navigational"
          onClick={onSignUpButtonClick}
        >
          {t("common.signUpButton")}
        </ButtonWithAnalytics>
        <ButtonWithAnalytics
          color="info"
          fullWidth
          page="WelcomeFlow"
          text="Sign in"
          intent="navigational"
          onClick={onSignInButtonClick}
        >
          {t("common.signInButton")}
        </ButtonWithAnalytics>
      </Stack>
    );
  }

  return (
    <Stack flexGrow={1} minHeight="100%">
      <Stack
        justifyContent="space-between"
        flexGrow={1}
        spacing={2}
        minHeight="100%"
        sx={{
          p: 2,
          color: "primary.contrastText",
          position: "relative",
          zIndex: 1,
        }}
      >
        <Stack spacing={2}>
          <IconLoader icon="UnuLogo" sx={{ fontSize: convertPxToRem(90) }} />
          <Stack
            spacing={2}
            color="info"
            justifyContent="center"
            alignItems="center"
            textAlign="center"
          >
            <Typography color="inherit" variant="h2">
              {t("WelcomeLandingScreen.title")}
            </Typography>
            <Typography color="inherit" variant="body1">
              {t("WelcomeLandingScreen.message")}
            </Typography>
          </Stack>
        </Stack>

        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          position="relative"
        >
          <Fade in={true}>
            <Stack>
              <PreloadImage
                src={womanSmilingImage}
                alt={t("WelcomeLandingScreen.womanSmilingImageAlt")}
                position="fixed"
                bottom={{ xs: "-20%", lg: "-25%" }}
                left={{ xs: "10%", md: "20%", lg: "40%" }}
                sx={{
                  height: "80%",
                }}
              />
            </Stack>
          </Fade>
        </Stack>

        <Fade in={true}>
          <Stack
            width="100%"
            height="100%"
            justifyContent="center"
            alignItems="center"
          >
            <PreloadImage
              alt={t("WelcomeLandingScreen.womanSmilingImageAlt")}
              src={backgroundWatermarkImage}
              position="fixed"
              top="60%"
              zIndex={-1}
              sx={{
                height: "90%",
              }}
            />
          </Stack>
        </Fade>

        <Fade in={!isFetching}>
          <Stack spacing={2}>
            <Stack direction="row" justifyContent="space-between">
              <Stack justifyContent="center" alignItems="center" spacing={1}>
                <Stack
                  direction="row"
                  zIndex={2}
                  alignSelf="flex-start"
                  width="170px"
                >
                  <IconLoader
                    icon="LeafLeftIcon"
                    color="neutral"
                    sx={{ fontSize: convertPxToRem(50) }}
                  />
                  <Typography
                    textAlign="center"
                    fontWeight={400}
                    color="neutral.dark"
                    variant="subtitle2"
                  >
                    <Trans
                      i18nKey={"WelcomeLandingScreen.safetyMessage"}
                    ></Trans>
                  </Typography>
                  <IconLoader
                    icon="LeafRightIcon"
                    color="neutral"
                    sx={{ fontSize: convertPxToRem(50) }}
                  />
                </Stack>
                <Stack alignItems="center">
                  <Typography
                    fontWeight={400}
                    color="neutral.dark"
                    variant="subtitle2"
                  >
                    <Trans
                      i18nKey={"WelcomeLandingScreen.ratingMessage"}
                    ></Trans>
                  </Typography>
                  <IconLoader
                    icon="FiveStarRatingIcon"
                    color="neutral"
                    sx={{
                      fontSize: convertPxToRem(50),
                      height: convertPxToRem(20),
                    }}
                  />
                </Stack>
              </Stack>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignSelf="flex-end"
                position="relative"
                width={GetHelpFABConfig.floatingActionButtonWidth}
                pt={1}
              >
                <GetHelpCTAButton
                  eventName="action.contactSupportFab"
                  buttonColor="info"
                  onClick={onGetHelpClick}
                />
              </Stack>
            </Stack>

            {buttonsOrAPIError}
          </Stack>
        </Fade>
      </Stack>

      <Box>
        <FullscreenBackground color="primary.main" />
      </Box>
    </Stack>
  );
}
